<template>
    <div>
        <div class="full-width category-container m-t-36">
            <div class="tac container neue neueSettings">
                <Breadcrumbs :path="path" />
            </div>
        </div>
        <div class="full-width p10">
            <div class="tac container flexColumn">
                <h3 class="neue title neueSettings">La Venenta</h3>
                <p class="neue neueSettings"><b>Abbigliamento</b></p>
            </div>
        </div>
        <div class="full-width m-t-36 p10">
            <div id="infoTable" class="container neueSettings">
                <div class="container70">
                    <div class="row ">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Paese</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Italia</p>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Prodotti</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">La Venenta realizza <b>capi di abbigliamento</b> artigianali per il progetto di Upcycling Creation di On Earth.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Valore protetto / impegno</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Riscatto sociale, difesa delle fragilità, valorizzazione delle potenzialità umane.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Con che paesi lavora</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">La Cooperativa lavora in Italia con diverse realtà e soggetti, con particolare attenzione alle <b>persone in contesti fragili a rischio di esclusione sociale.</b></p>
                        </div>
                    </div>
                </div>
                <div class="container20">
                    <img class="quidLogo" :src="quidLogo.url" alt="" srcset="">
                    <img class="miniMap" :src="miniMap.url" alt="" srcset="">
                    <a href="https://www.venenta.it" target="_blank">www.venenta.it</a>
                </div>
            </div>
        </div>
        <div id="slider" class="mb-6r">
            <VueSlickCarousel v-bind="settings">
                <img src="@/assets/lavenenta/1.jpg" alt="">
                <img src="@/assets/lavenenta/2.jpg" alt="">
                <img src="@/assets/lavenenta/3.jpg" alt="">
                <img src="@/assets/lavenenta/4.jpg" alt="">
                <img src="@/assets/lavenenta/5.jpg" alt="">
            </VueSlickCarousel>
        </div>
        <div class="full-width mb-4r p10">
            <div class="container flexColumn">
                <div class="row flexColumn">
                    <h3 class="neue title neueSettings">Descrizione</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">La <b>Cooperativa sociale La Venenta</b> nasce per volontà dell’associazione Comunità Santa Maria della Venenta Onlus e al suo interno offre <b>servizi socio-educativi</b> per diverse realtà e soggetti, sia pubblici che privati, tra i quali servizi per le scuole, formazione alla cittadinanza, accoglienza di persone con disabilità e supporto a vittime di violenza.<br>
La Cooperativa ha anche l’obiettivo di garantire servizi socio-educativi professionali e di qualità e offre una situazione protetta per categorie fragili. L'<b>area di inclusione lavorativa intende valorizzare le risorse di persone che vivono in contesti fragili a rischio di esclusione sociale</b>, fornendo loro competenze all’interno di un contesto tutelato. Attraverso la creazione di capi di abbigliamento, accessori e oggettistica, <b>le persone hanno la possibilità di costruirsi una professionalità e aspirare alla graduale autonomia socio-economica</b>.<br>
Questo è ciò che accade per esempio nel Laboratorio Artigianale Tessile – Pittorico, dove attraverso l’acquisizione di competenze e abilità nel settore tessile, moda e abbigliamento, vengono costruiti percorsi per valorizzare e aumentare le potenzialità di ogni persona.</p>
                </div>   
                <div class="row flexColumn m-t-36">
                    <h3 class="neue title neueSettings">Curiosità</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">La Cooperativa sociale La Venenta include al suo interno un centro anti-violenza, accompagnato da molte attività e iniziative a supporto delle donne per rispondere ai bisogni di integrazione sociale, culturale e lavorativa di donne straniere con figli, vittime di violenza, ospiti delle strutture della cooperativa e/o segnalate dai servizi sociali.</p>
                </div> 
            </div>
        </div>
        <ProductSlider supplier="9816334" />
    </div>
</template>
<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    const meridianoLogo = require('../assets/9816334.png')

    export default {
        name : 'LaVenenta',
        components : {
            Breadcrumbs,
            ProductSlider,
            VueSlickCarousel
        },

        metaInfo: {
          title: 'La Venenta'
        },

        data() {
            return {
                path: [
                    { label: 'Home', path: '/' },
                    { label: 'Brand', path: '/brand' },
                    { label: 'La Venenta', path: '/la-venenta' }
                ],
                miniMap : {
                    url : 'https://www.altromercato.it/wp-content/uploads/2021/09/Bosnia-ed-Erzegovina.jpg'
                },
                quidLogo : {
                    url : meridianoLogo
                },
                settings : {
                    "arrows": true,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "speed": 500,
                    "cssEase": "linear",
                    "responsive": [
                        {
                            "breakpoint": 668,
                            "settings": {
                                "speed": 500,
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "infinite": true,
                                "dots": false
                            }
                        },
                    ]
                }
            }
        }
    }

</script>
<style >
    #slider .slick-next {
        right: 67px;
    }
    #slider .slick-prev {
        left: 67px;
        z-index: 1;
    }
    .mb-4r{
        margin-bottom: 4rem;
    }
    .mb-6r{
        margin-bottom: 6rem;
    }
    .neueSettings{
        letter-spacing: 0.5px;
        line-height: 1.5em;
    }
    .miniMap{
        max-width: 100%;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        padding: 10px;
        margin-bottom: 2rem;
    }
    .quidLogo{
        max-width: 50%;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
    }
    .container70{
        max-width: 75%;
    }
    .container20{
        max-width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-120px);
    }
    .neueSetBold{
        font-weight: 600;
    }
    .flexColumn{
        flex-direction: column;
    }
    .col-25{
        width: 30%;
        max-width: 30%;
    }
    .col-50{
        width: 50%;
        max-width: 50%;
    }
    .col-60{
        width: 60%;
        max-width: 60%;    
    }
    .col-75{
        width: 75%;
        max-width: 75%;
    }
    @media(max-width: 668px){
        #infoTable{
            flex-direction: column;
        }
        #infoTable .container70{
            max-width: 100%;
        }
        #infoTable .container20{
            transform: unset;
            max-width: 100%;
        }
        #infoTable .quidLogo{
            max-width: 20%;
            margin-top: 2rem;
        }
        #infoTable a{
            margin-bottom: 4rem;
        }
        .col_sm-100{
            max-width: 100%;
            width: 100%;
        }
        .p10{
            padding: 10px;
        }
        .tac{
            text-align: center;
        }
    }
</style>

